<template>
    <div
        class="d-flex navbar-mobile-logo"
        :class="{
            'navbar-mobile-logo--centered': isRoot,
            'navbar-mobile-logo--large': logoMobileLarge,
        }"
    >
        <NuxtLink :to="{ path: currentLocalePath }">
            <IconsLumaClaim class="navbar-mobile-logo__logo" />
        </NuxtLink>
    </div>
    <nav class="navbar" :class="{ 'navbar--extended': extendedNav }">
        <div class="container container--main">
            <div class="navbar__desktop">
                <button
                    @click="previousLayer(menu.data.value.primary)"
                    v-if="showMenu && selectedLayer != 1"
                    class="navbar-toggler navbar-toggler__back"
                >
                    <IconsWrapper size="20px">
                        <IconsArrowLeft />
                    </IconsWrapper>
                </button>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar"
                    aria-controls="offcanvasNavbar"
                    @click="toggleMenu()"
                    ref="toggler"
                >
                    <div class="navbar-burger-background">
                        <div
                            class="navbar-burger"
                            :class="{ 'navbar-burger--active': showMenu }"
                        >
                            <span class="navbar-burgerLine"></span>
                            <span class="navbar-burgerLine"></span>
                            <span class="navbar-burgerLine"></span>
                        </div>
                    </div>
                </button>
                <NuxtLink
                    @click="showMenu ? closeMenu() : ''"
                    class="navbar-brand navbar-brand__large"
                    :to="{ path: currentLocalePath }"
                >
                    <IconsLumaClaim class="navbar-brand__icon" />
                </NuxtLink>
                <ul class="navbar-nav">
                    <li
                        class="nav-item"
                        data-bs-toggle="modal"
                        data-bs-target="#search-mask"
                        @click="startSearch"
                    >
                        <IconsWrapper size="20px">
                            <IconsSearch />
                        </IconsWrapper>
                    </li>
                    <li
                        data-cy="openCartButton"
                        class="nav-item"
                        data-bs-toggle="modal"
                        id="cartButton"
                        data-bs-target="#cart"
                        @click="trackViewCart()"
                    >
                        <IconsWrapper size="20px">
                            <BasketIcon />
                        </IconsWrapper>
                    </li>
                </ul>
            </div>
            <div class="navbar__mobile d-none">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <FormButton
                            @click="previousLayer(menu.data.value.primary)"
                            v-if="showMenu && selectedLayer != 1"
                            class="l-button--icon-only l-button--secondary"
                        >
                            <IconsWrapper size="20px">
                                <IconsArrowLeft />
                            </IconsWrapper>
                        </FormButton>
                        <FormButton
                            v-else
                            class="l-button--icon-only l-button--secondary"
                            data-bs-toggle="modal"
                            data-bs-target="#search-mask"
                            @click="startSearch"
                        >
                            <IconsSearch />
                        </FormButton>
                    </li>
                    <li class="nav-item">
                        <FormButton
                            class="l-button--icon-only"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar"
                            aria-controls="offcanvasNavbar"
                            @click="toggleMenu()"
                        >
                            <div
                                class="navbar-burger"
                                :class="{ 'navbar-burger--active': showMenu }"
                            >
                                <span class="navbar-burgerLine"></span>
                                <span class="navbar-burgerLine"></span>
                                <span class="navbar-burgerLine"></span>
                            </div>
                        </FormButton>
                    </li>
                    <li
                        class="nav-item"
                        data-bs-toggle="modal"
                        data-bs-target="#cart"
                        data-cy="openCartButton"
                    >
                        <FormButton
                            class="l-button--icon-only l-button--secondary"
                            data-bs-toggle="modal"
                            data-bs-target="#cart"
                        >
                            <BasketIcon />
                        </FormButton>
                    </li>
                </ul>
            </div>
            <div
                class="offcanvas offcanvas-top"
                tabindex="-1"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
                data-bs-backdrop="false"
                ref="offcanvasNavigation"
            >
                <div class="offcanvas-body" ref="offcanvasBody">
                    <DelayHydration>
                        <div
                            class="row justify-content-evenly navigation-inner"
                            :class="{ 'd-flex': selectedLayer === 1 }"
                        >
                            <div class="col-800-4">
                                <ul
                                    class="pages"
                                    v-if="menu.data.value?.primary"
                                >
                                    <li
                                        v-for="child in menu.data.value.primary"
                                        :key="child.id"
                                        :class="{
                                            'scale-5': child.large,
                                            'scale-3': !child.large,
                                        }"
                                    >
                                        <div
                                            v-if="child.subnavigation"
                                            @click="toggleLayer(child)"
                                            class="nav-item-l1"
                                            :class="{
                                                'nav-item-l1--highlight':
                                                    child.large,
                                            }"
                                        >
                                            <span class="nav-item-l1-label">{{
                                                child.label
                                            }}</span>
                                            <IconsWrapper
                                                v-if="child.large"
                                                size="35px"
                                                class="icon"
                                            >
                                                <IconsArrowRight />
                                            </IconsWrapper>
                                            <IconsWrapper
                                                v-else
                                                size="20px"
                                                class="icon"
                                            >
                                                <IconsArrowRight />
                                            </IconsWrapper>
                                        </div>
                                        <NuxtLink
                                            v-else
                                            @click="closeMenu()"
                                            :to="child.url"
                                            class="nav-item-l1"
                                            :class="{
                                                'nav-item-l1--highlight':
                                                    child.large,
                                            }"
                                        >
                                            <span class="nav-item-l1-label">{{
                                                child.label
                                            }}</span>
                                        </NuxtLink>
                                    </li>
                                </ul>
                                <div class="separator"></div>
                            </div>
                            <div class="col-800-3 mb-120">
                                <ul
                                    class="pages pages-inner"
                                    v-if="menu.data.value?.secondary"
                                >
                                    <li
                                        v-for="child in menu.data.value
                                            .secondary"
                                        :key="child.id"
                                    >
                                        <NuxtLink
                                            @click="closeMenu()"
                                            :to="child.url"
                                            class="scale-3"
                                        >
                                            {{ child.label }}
                                        </NuxtLink>
                                    </li>
                                </ul>
                                <hr />
                                <div class="d-flex justify-content-between">
                                    <ClientOnly>
                                        <div class="login-wrapper">
                                            <FormButton
                                                @click="accountButton()"
                                                :label="
                                                    authenticated
                                                        ? t(
                                                              'navigation.account',
                                                          )
                                                        : t('navigation.login')
                                                "
                                                class="l-button--secondary l-button--auto-width account__button"
                                                :size40="true"
                                                data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasNavbar"
                                                aria-controls="offcanvasNavbar"
                                            >
                                                <IconsProfile />
                                            </FormButton>
                                        </div>
                                    </ClientOnly>
                                    <div
                                        class="align-self-center locale-changer"
                                    >
                                        <template
                                            v-for="localeChoice in strippedLocales"
                                            :key="localeChoice"
                                        >
                                            <input
                                                class="locale-changer__input"
                                                type="radio"
                                                :id="localeChoice"
                                                :value="localeChoice"
                                            />
                                            <a
                                                :href="localeUrl(localeChoice)"
                                                @click="
                                                    $event.preventDefault();
                                                    changeLanguage(
                                                        localeChoice,
                                                    );
                                                "
                                                class="equal-base locale-changer__label"
                                                :class="
                                                    localeClass(localeChoice)
                                                "
                                                :for="localeChoice"
                                            >
                                                {{ localeChoice }}
                                            </a>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-for="child in menu.data.value?.primary"
                            :key="child.id"
                        >
                            <div v-if="!child.tags">
                                <div
                                    class="row navigation-inner navigation-l2"
                                    :class="{
                                        'd-flex': selectedLayer === child.id,
                                    }"
                                >
                                    <div class="col-800-12">
                                        <div class="nav-inner-wrapper">
                                            <div
                                                class="nav-item-inner"
                                                v-for="(
                                                    grandchild, index
                                                ) in child.children"
                                                :key="grandchild.id"
                                            >
                                                <span
                                                    class="equal-base--tighter mb-3"
                                                    v-if="index === 0"
                                                >
                                                    {{ child.label }}
                                                </span>
                                                <NuxtLink
                                                    @click="closeMenu()"
                                                    :to="grandchild.url"
                                                >
                                                    <NuxtImg
                                                        width="322"
                                                        densities="1x 2x"
                                                        :src="
                                                            grandchild
                                                                .menu_image
                                                                ?.src ||
                                                            'https://placeholder.co/600x400'
                                                        "
                                                        :alt="
                                                            grandchild
                                                                .menu_image?.alt
                                                        "
                                                        loading="lazy"
                                                        format="webp"
                                                        placeholder
                                                    />
                                                </NuxtLink>
                                                <NuxtLink
                                                    v-if="grandchild.url"
                                                    @click="closeMenu()"
                                                    :to="grandchild.url"
                                                    class="scale-1"
                                                >
                                                    {{ grandchild.label }}
                                                </NuxtLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="row navigation-inner navigation-l3"
                                :class="{
                                    'd-flex': selectedLayer === child.id,
                                }"
                                style="
                                    max-width: 60%;
                                    margin-left: auto;
                                    margin-right: auto;
                                "
                                v-else
                            >
                                <div
                                    class="col-800-4 mb-4"
                                    v-for="item in child.children"
                                >
                                    <div @click="closeMenu()" class="small-1">
                                        {{ item.name }}
                                    </div>
                                    <ul>
                                        <li
                                            class="equal-base--tighter"
                                            v-for="grandchild in item.children"
                                            :key="grandchild.id"
                                        >
                                            <NuxtLink
                                                @click="closeMenu()"
                                                :to="grandchild.url"
                                                v-if="grandchild.url"
                                            >
                                                {{ grandchild.label }}
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </DelayHydration>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import BasketIcon from './BasketIcon.vue';
import { useBasketStore } from '~/stores/basket';
import { onMounted } from 'vue';
import { onBeforeMount } from 'vue';
import { watch } from 'vue';
import { navigateTo } from 'nuxt/app';
import { useI18NStore } from '~/stores/i18n';
import { useWagtailClient } from '~/composables/useWagtailClient';
import { useTrackViewCart } from '~/composables/gtm/useEcomTracking';

const basketStore = useBasketStore();

await useAsyncData(
    'basket',
    async () => {
        basketStore.updateBasket();
        return basketStore.lines;
    },
    {
        server: false,
        lazy: true,
    },
);

const toggler = ref(null);
const { t, locale, availableLocales, setLocale } = useI18n();
const router = useRouter();
const localeRoute = useLocaleRoute();
const route = useRoute();
const scrolledDown = ref(false);
const offcanvasNavigation = ref(null);
const i18nStore = useI18NStore();
const offcanvasBody = ref(null);
const basketLoading = toRef(basketStore, 'loading');

const localeUrl = computed(() => (code) => {
    const url = i18nStore?.hreflang ? i18nStore?.hreflang[code] : null;
    if (url) {
        return url;
    } else {
        return `/${code}/`;
    }
});

const changeLanguage = (code) => {
    const url = i18nStore?.hreflang[code];
    if (url) {
        navigateTo(url);
    } else {
        navigateTo(`/${code}/`);
    }
    setLocale(code);
    closeMenu();
};

const startSearch = function (e) {
    closeMenu();
};

const isRoot = computed(() => {
    return ['/de', '/de/', '/fr', '/fr/', '/'].includes(route.path);
});

const extendedNav = computed(() => {
    return process.server ? isRoot.value : scrolledDown.value;
});

const strippedLocales = computed(() => {
    return availableLocales.filter((x) => {
        return x !== 'en';
    });
});

const menu = await useAsyncData(
    'menu' + locale.value,
    async () => {
        if (process.server) {
            const language = router.currentRoute.value.path.split('/')[1];
            if (availableLocales.includes(language)) {
                setLocale(language);
            }
        }
        const wagtailClient = useWagtailClient(false, '', '', locale.value);
        const response = await wagtailClient.getMenuItems();
        response.data.primary.forEach((e) => (e.id += 100));
        return response.data;
    },
    {
        server: true,
        watch: locale,
    },
);

const currentLocalePath = computed(() => {
    return `/${locale.value}/`;
});

const localeClass = computed(() => (choice) => {
    return choice === locale.value ? 'locale-changer__label--selected' : '';
});

defineProps({
    logoMobileCentered: {
        type: Boolean,
        default: false,
    },
    logoMobileLarge: {
        type: Boolean,
        default: false,
    },
});

const showMenu = ref(false);
const selectedLayer = ref(1);

const toggleLayer = (item) => {
    if (item.children?.length !== 0) {
        selectedLayer.value = item.id;
    }
};

function findParent(id, items, parent) {
    if (items.some((item) => item.id === id)) {
        if (!parent) return 1;
        return parent;
    }
    return items.reduce((acc, item) => {
        if (item.children) {
            const found = findParent(id, item.children, item.id);
            if (found) return found;
        }
        return acc;
    }, null);
}

const previousLayer = (items) => {
    selectedLayer.value = findParent(selectedLayer.value, items);
};

const toggleMenu = () => {
    if (!showMenu.value) {
        try {
            document.body.style.overflow = 'hidden';
            document.getElementsByClassName('navbar')[0].focus();
        } catch {}
    }

    showMenu.value = !showMenu.value;
    if (isRoot.value) {
        if (showMenu.value) {
            scrolledDown.value = false;
        } else {
            adaptNavigation();
        }
    }

    setTimeout(() => {
        toggleLayer({ id: 1 });
    }, 300);
};

const closeMenu = () => {
    const burger = document.getElementsByClassName('navbar-burger--active')[0];
    offcanvasBody.value.scroll(0, 0);
    if (burger) {
        burger.parentElement.click();
    }
};

// login
const authStore = useAuthStore();
const { authenticated } = storeToRefs(authStore);

async function accountButton() {
    toggleMenu();

    if (!authenticated.value) {
        await authStore.login('', locale.value);
    } else {
        const route = localeRoute({ name: 'account' }, locale.value);
        return navigateTo(route.fullPath);
    }
}

function adaptNavigation() {
    if (isRoot.value) {
        if (process.client) {
            if (window.scrollY > 10) {
                scrolledDown.value = false;
            } else {
                scrolledDown.value = true;
            }
        } else {
            scrolledDown.value = true;
        }
    } else {
        scrolledDown.value = false;
    }
}

function trackViewCart() {
    if (basketLoading.value) {
        const unwatch = watch(basketLoading, (newV, oldV) => {
            if (newV === false) {
                useTrackViewCart(
                    basketStore?.lines,
                    basketStore?.basket?.total_incl_tax,
                );
            }
            unwatch();
        });
    } else
        useTrackViewCart(
            basketStore?.lines,
            basketStore?.basket?.total_incl_tax,
        );
}

watch(isRoot, () => {
    adaptNavigation();
});

onBeforeMount(() => {
    adaptNavigation();
});

onMounted(() => {
    if (process.client) {
        window.addEventListener('scroll', adaptNavigation);
        offcanvasNavigation.value.addEventListener(
            'hidden.bs.offcanvas',
            function () {
                adaptNavigation();
                if (showMenu.value) {
                    showMenu.value = false;
                }
            },
        );
    }
});
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.navbar__mobile {
    .navbar-burger {
        margin-top: 3px;
        margin-left: -2px;
        transition: margin-left 400ms;
    }

    .navbar-burger--active {
        margin-left: 1px;
        left: 0;
    }

    .navbar-burgerLine {
        background-color: #000;
    }
}

a {
    text-decoration: none;
}

.navbar--extended {
    border-bottom-color: #000 !important;

    .navbar__desktop {
        background-color: #000;

        .navbar-brand {
            top: 40px;
            width: clamp(190px, 6.83vw + 151.73px, 272px) !important;

            #brand-simple,
            #brand-complex {
                transition: all 200ms;
            }

            #brand-simple {
                opacity: 0;
            }

            #brand-complex {
                opacity: 1;
            }
        }
    }
}

.navbar-mobile-logo {
    position: relative;
    z-index: 100;
    height: 50px;
    margin-top: 30px;

    a {
        display: block;
        width: 140px;
        height: 50px;
    }

    @include media-breakpoint-up(560) {
        display: none !important;
    }

    &--centered {
        justify-content: center;
    }

    &--large {
        height: 105px;
    }
}

.navbar--extended {
    .navbar__desktop {
        border-bottom: 0;
    }
}
li {
    transition: 0.5s ease;
    .IconsWrapper {
        transition: 0.5s ease;
    }

    .nav-item-l1-label {
        transition: 0.5s ease;
    }
}
a {
    transition: 0.5s ease;
}
li a:hover,
.nav-item-l1:hover {
    .IconsWrapper {
        fill: $color-plain-grey-60;
        transition: 0.5s ease;
    }

    .nav-item-l1-label {
        color: $color-plain-grey-60;
        transition: 0.5s ease;
    }
    color: $color-plain-grey-60;
    transition: 0.5s ease;
    a {
        color: $color-plain-grey-60;
        transition: 0.5s ease;
    }
}

.navbar {
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    background-color: #000;
    transition: all 400ms;
    z-index: 1000;
    &__desktop {
        z-index: 1046;
        display: flex;
        height: 70px;
        width: 100%;
        border-bottom: 1px solid #333333;
    }

    .container-fluid {
        padding: 0;
    }

    .navbar-toggler {
        padding: 0;
        border: none;
        .navbar-burger-background {
            padding: 17px 12px 17px 12px;
            border-radius: 50%;
            transition: 0.5s ease;
            &:hover {
                transition: 0.5s ease;
                background-color: $color-plain-grey-15;
                padding: 17px 12px 17px 12px;
                border-radius: 50%;
            }
        }

        &:focus {
            border: none;
            box-shadow: none;
        }

        &__back {
            margin-right: 30px;
        }
    }

    .navbar-brand {
        position: absolute;
        left: 50%;
        top: 22px;
        transform: translateX(-50%);
        width: 130px !important;
        transition: all 400ms;
        margin: 0;
        padding: 0;

        &__icon {
            width: auto;
            color: $color-light;
        }

        #brand-simple {
            opacity: 1;
        }

        #brand-complex {
            opacity: 0;
        }
    }

    .navbar-nav {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        align-items: center;
        li {
            padding: 15px !important;
            border-radius: 50%;
            transition: 0.5s ease;
        }
        li:hover {
            transition: 0.5s ease;
            background-color: $color-plain-grey-15;
            padding: 15px;
            border-radius: 50%;
        }
        .nav-item {
            padding: 0 16px;
            cursor: pointer;
            //transition: 0.5s ease;

            .close-button {
                border-color: #333333;
            }
        }
    }
}

.offcanvas {
    top: 70px !important;
    height: calc(100dvh - 69px) !important;
    background-color: #000;
    display: flex;
    overflow-y: scroll;
    align-items: safe center;

    @media (max-height: 590px) {
        align-items: start;
    }

    flex-direction: row;

    .offcanvas-body {
        width: 100%;
        align-self: safe center;
        padding-bottom: 100px;
        margin-top: auto;
        margin-bottom: auto;

        @include media-breakpoint-down(800) {
            height: 100%;
        }

        .row {
            margin: 0;
        }
    }
}

.nav-item-l1-label {
    @media (min-width: 890px) {
        max-width: 235px;
    }

    @media (min-width: 1140px) {
        max-width: 330px;
    }

    @media (min-width: 1475px) {
        max-width: 430px;
    }

    @media (min-width: 1937px) {
        max-width: none;
    }
}

.navigation-inner {
    display: none;

    .pages {
        list-style: none;
        padding: 0;
        cursor: pointer;

        @include media-breakpoint-down(800) {
            margin-top: 30px;
        }

        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 7px;
        }

        .scale-3 {
            text-transform: none;
        }

        .nav-item-l1 {
            display: flex;
            align-items: center;
            margin-bottom: 31px;

            &:hover {
                color: $color-light !important;
            }

            &--highlight {
                margin-bottom: 37px;

                .icon {
                    margin-bottom: 5px;
                }
            }
        }
    }

    .pages-inner {
        li {
            margin-bottom: 31px;
        }
    }

    &.navigation-l2 {
        margin-top: 126px;
        justify-content: flex-start;

        .equal-base--tighter {
            text-transform: uppercase;
            color: #737373;
            font-weight: bold;
        }

        .nav-inner-wrapper {
            margin-top: 34px;
            max-width: 1180px;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
        }

        .nav-item-inner {
            display: flex;
            flex-direction: column;

            img {
                width: 322px;
                height: 180px;
                object-fit: cover;
                cursor: pointer;
            }

            .scale-1 {
                margin-top: 10px;
                max-width: 322px;
            }

            &:hover {
                .scale-1 {
                    color: $color-plain-grey-60;
                    transition: 0.5s ease;
                }
            }
        }

        .nav-inner-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 40px;
            justify-content: center;
            align-items: flex-end;
        }

        @include media-breakpoint-down(560) {
            padding-bottom: 80px;
        }
    }

    &.navigation-l3 {
        margin-top: 126px;
        justify-content: flex-start;

        ul {
            margin-top: 21px;
            list-style: none;
            padding: 0;

            li {
                margin-bottom: 18px;
                font-size: 18px;
            }
        }

        .small-1 {
            text-transform: uppercase;
            color: #737373;
            font-weight: bold;
        }

        @include media-breakpoint-down(560) {
            padding-top: 20px;
            padding-bottom: 50px;
        }
    }
}

@include media-breakpoint-down(560) {
    .navbar {
        top: unset;
        bottom: 20px;
        background-color: transparent;
        border: 0;

        &__desktop {
            display: none;
        }

        &__mobile {
            display: flex !important;
            margin: auto;
            height: 100%;
            padding: 5px;
            margin: auto;
            background-color: #262626;
            border-radius: 30px;
            z-index: 1100;
        }

        .navbar-nav {
            display: flex;
            flex-direction: row;
            margin-left: auto;
            align-items: center;

            .nav-item {
                padding: 0 !important;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }

    .offcanvas {
        top: 0 !important;
        height: 100vh !important;
    }
}

.account {
    &__button {
        background-color: $color-dark-02 !important;
        border-color: $color-dark-02 !important;

        .l-button__label {
            margin: 0 20px 0 5px;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $color-plain-grey-15 !important;
        }
    }
}

.locale-changer {
    &__input {
        display: none;
    }

    &__label {
        text-transform: uppercase;
        margin-left: 15px;
        color: $color-dark-01;
        cursor: pointer;

        &--selected {
            color: $color-light;
            cursor: not-allowed;
        }
    }
}

.navbar-burger {
    height: 16px;
    position: relative;
    width: 26px;
    display: block;
    transition: left 400ms;
    left: 0;
    &:hover {
        cursor: pointer;
    }
}

.navbar-burgerLine {
    height: 0.125rem;
    background-color: #fff;
    width: 26px;
    transition: all 400ms;
    display: block;
    position: absolute;
    left: 0;
    opacity: 1;
    transform-origin: 13% 50%;
}

.navbar-burgerLine:first-child {
    top: 0;
}

.navbar-burgerLine:nth-child(2) {
    top: 7px;
}

.navbar-burgerLine:last-child {
    top: 14px;
}

.navbar-burger--active {
    transition: left 400ms;
    left: 3px;
    .navbar-burgerLine {
        height: 0.125rem;
    }

    .navbar-burgerLine:first-child {
        top: 0;
        height: 0.14rem;
        transform: rotate(45deg);
    }

    .navbar-burgerLine:nth-child(2) {
        opacity: 0;
    }

    .navbar-burgerLine:last-child {
        top: 13px;
        height: 0.125rem;
        transform: rotate(-45deg);
    }
}

.nav-plus {
    transition: all 400ms;
    transform-origin: 50% 50%;
    transform: translate(0) !important;
    top: 0 !important;
    left: 0 !important;
}

.nav-plus--rotated {
    transform: rotate(135deg) translate(0) !important;
}

.separator {
    border: 1px solid #666;
    margin-top: -20px;
    margin-bottom: 40px;
    display: block;

    @include media-breakpoint-up(800) {
        display: none;
    }
}

@media print {
    .navbar {
        display: none;
    }
}
</style>
